input:focus {
  color: var(--main-color);
  box-shadow: none;
  outline: none;
  border-color: var(--main-color);
  background-color: transparent;
}

.form-admin .form-control,
.form-admin .form-select {
  border: 1px solid #fb9503 !important;
  background: transparent;
  border-radius: 20px;
  color: #000000;
}

.form-admin .form-select {
  background: #fb9503 !important;
}

.form-control.cost {
  text-align: center;
}

.form-select {
  cursor: pointer;
}

.form-control::placeholder {
  color: var(--main-color);
}

.form-control:focus {
  color: var(--main-color);
  box-shadow: none;
  outline: none;
  border-color: var(--main-color);
  background-color: transparent;
}
.form-select::placeholder {
  color: var(--main-color);
}

.form-select:focus {
  color: var(--main-color);
  box-shadow: none;
  outline: none;
  border-color: var(--main-color);
  background-color: transparent;
}

textarea.form-control {
  height: 100px;
}

.form-grey {
  background-color: var(--grey-color);
  padding: 2.2rem 1.4rem;
  border-radius: 18px;
  background-color: #323232;
}

.form-black {
  background-color: #0d0d0d;
  padding: 1.5rem;
  border-radius: 0 0 18px 18px;
}

.form-black .form-label {
  color: #fb9503;
}

.form-black .form-control,
.form-black .form-select {
  border: 1px solid #fb9503 !important;
  background: transparent;
  border-radius: 20px;
  color: #fb9503;
}
.box-form {
  border: 1px solid var(--main-color);
  background: transparent;
  border-radius: 20px;
  color: var(--main-color);
  padding: 1rem 0.8rem;
}

.box-form .form-label {
  margin-bottom: 3rem;
}

.box-form .btn-main {
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 500;
}

.number-select-input {
  border: 1px solid #fb9503;
  background: transparent;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
}

.number-select-input .campo-amount {
  width: 100px;
  padding: 0 5px;
}

.number-select-input .campo-amount input {
  background-color: transparent;
  border: 0;
  color: #fb9503;
  width: 100%;
  text-align: center;
}

.number-select-input .icon-cgp {
  background-color: transparent;
  color: #fb9503;
  border: 0;
  padding: 5px 10px;
  cursor: pointer;
}
.number-select-input .icon-cgp:first-child {
  border-right: 1px solid var(--main-color);
}
.number-select-input .icon-cgp:last-child {
  border-left: 1px solid var(--main-color);
}

.container-check {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  color: var(--main-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid var(--main-color);
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  /* background-color: #e59880; */
  background-color: var(--main-color);
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 7px;
  top: 5px;
  width: 7px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.password-view,
.icon-view {
  position: absolute;
  right: 27px;
  top: 5px;
  cursor: pointer;
}

.password-view img,
.icon-view img {
  width: 23px;
}

.eye-hidden {
  display: none;
}

#password-register-confirm.active .eye-hidden {
  display: initial;
}

#password-register-confirm.active .eye {
  display: none;
}

#password-register.active .eye-hidden {
  display: initial;
}

#password-register.active .eye {
  display: none;
}

.btn-close {
  color: var(--main-color);
}

.btn-close {
  opacity: 1;
  width: 25px;
  height: auto;
}

.btn-close img {
  width: 100%;
  height: auto;
}

.form-admin-user-invesment .form-control,
.form-admin-user-invesment .form-select {
  background-color: #232323;
  color: var(--main-color);
  border: none !important;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem;
  font-size: 30px;
  font-weight: 500;
  border-color: #232323;
}

@media (min-width: 992px) {
  textarea.form-control {
    height: 150px;
  }
}

@media (min-width: 1200px) {
  .form-label {
    font-size: 14px;
  }
  .box-form .form-label {
    margin-bottom: 1.5rem;
  }
  .box-form .btn {
    margin-left: auto;
    width: auto;
  }
}
