.btn {
  border-radius: 20px;
  width: 180px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.btn-group-admin .btn {
  width: auto;
}

.btn-admin {
  background-color: #fb9503;
  color: #000000;
  border-color: #fb9503;
}

.btn-admin:hover {
  background-color: #000000;
  color: #fb9503;
  border-color: #000000;
}

.btn-main {
  background-color: var(--main-color);
  color: var(--black-color);
}

.btn-main:hover {
  background-color: transparent;
  color: var(--main-color);
  border-color: var(--main-color);
}

.btn-main:disabled {
  background-color: transparent;
  color: var(--main-color);
  border-color: var(--main-color);
}

.btn-black {
  background-color: var(--black-color);
  color: var(--main-color);
}

.btn-black:hover {
  background-color: var(--main-color);
  color: var(--black-color);
}

.btn-blue {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.btn-blue:hover {
  background-color: var(--main-color);
  color: var(--secondary-color);
}

.btn-transparent {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background-color: transparent;
  width: 160px;
  padding: 0.3rem 0;
  font-size: 16px;
}

.btn-transparent img {
  width: 20px;
  margin-right: 0.5rem;
}

.btn-transparent:hover {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background-color: transparent;
}

.btn-white {
  background-color: var(--white-color);
  color: var(--main-color);
}

.btn-white:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.btn-tab-details {
  background-color: var(--white-color);
  color: #575757;
  border-color: var(--main-color);
}

.btn-tab-details:hover {
  background-color: var(--main-color);
  color: #575757;
}

.btn-tab-details.active {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: #575757;
}

.card .btn-primary {
  background-color: #fb9503;
  color: #000000;
  border-color: #fb9503;
}

.card .btn-primary:hover {
  background-color: var(--white-color);
  background-color: var(--main-color);
  background-color: #fb9503;
  color: #575757;
}

.btn-login-social {
  width: auto;
  background-color: var(--white-color);
  border-radius: 7px;
  padding: 0.5rem;
}

.btn-login-social img {
  width: 24px;
}

.back-r-l {
  position: absolute;
  right: 0;
  top: -50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.btn-table {
  background-color: #4f4f4f;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-change-img-profile {
  background-color: #000000;
  color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-center;
  font-size: 1rem;
}

.btn-change-img-profile:hover {
  background-color: #ffffff;
  color: #fb9503;
}

.page-link {
  background-color: #fbb304;
  color: #000000 !important;
  border-radius: 5px !important;
  width: 100px;
  text-align: center;
}

.page-link:nth-child(1) {
  margin-right: 1rem;
}

.disabled > .page-link,
.page-link.disabled {
  color: rgba(33, 37, 41, 0.75) !important;
  background-color: #e9ecef !important;
}

@media (max-width: 991.98px) {
  .back-r-l {
    right: 10px;
    top: auto;
    bottom: -3rem;
  }
}
