/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 0;
  width: 300px;
  /* background-color: var(--black-color); */
  background-color: transparent;
  position: absolute;
  height: 100%;
  overflow: auto;
  gap: 0.3rem;
  display: flex;
  flex-direction: column;
}

/* Sidebar links */
.sidebar a {
  display: block;
  color: #000000;
  padding: 11px 0 11px 2rem;
  text-decoration: none;
  border-top: 1px solid #ffffff;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fb9503;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0% 100%);
  width: 80%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.sidebar a .icon {
  width: auto;
  margin-right: 0.5rem;
  width: 32px;
  height: 32px;
}

/* Active/current link */
.sidebar a.active {
  /* background-color: var(--main-color); */
  color: #ffffff;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 94% 100%, 0% 100%);
  padding: 16px 0 16px 2rem;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  /* background-color: var(--main-color); */
  color: #ffffff;
}

.white-icon {
  display: block;
}

.orange-icon {
  display: none;
}

.sidebar a.active .white-icon {
  display: none;
}

.sidebar a.active .orange-icon {
  display: block;
}

.sidebar a:hover:not(.active) .orange-icon {
  display: block;
}

.sidebar a:hover:not(.active) .white-icon {
  display: none;
}

.sidebar-profile {
  background-color: #ededed;
  display: flex;
  align-items: center;
  padding: 3rem 0 3rem 2rem;
}

.sidebar-profile-img {
  width: 80px;
  height: 80px;
  background-color: #000000;
  border-radius: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-profile-name {
  font-size: 18px;
  font-weight: bold;
  margin-left: 0.6rem;
  color: var(--black-color);
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
