.tab {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  color: #ededed;
  font-size: 17px;
}

.tab button.left.active {
  border-radius: 0 30px 30px 0;
}

.tab button.right.active {
  border-radius: 30px 0px 0px 30px;
}

.tab button.active {
  background-color: #ededed;
  /* color: #6642de; */
  color: #fb9503;
}

.tab button:hover {
  background-color: #ededed;
  /* color: #6642de; */
  color: #fb9503;
}

.tab button.left:hover {
  border-radius: 0 30px 30px 0;
}

.tab button.right:hover {
  border-radius: 30px 0 0 30px;
}

/* Style the tab content */
.tabcontent {
  display: none;
  animation: fadeEffect 1s; /* Fading effect takes 1 second */
  margin-top: 2rem;
}

@media (min-width: 1400px) {
  .tab button {
    font-size: 20px;
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 991.98px) {
  .tab button {
    font-size: 25px;
    padding: 1rem 2rem;
  }

  .tab button.right.active {
    border-radius: 35px 0 0 35px;
  }
}

@media (max-width: 400.98px) {
  .tab button {
    font-size: 23px;
    padding: 1rem 1.5rem;
  }
}
