/* @import url(assets/css/style.css); */
@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

@import url("assets/css/components/form/");
@import url("assets/css/components/button/");
@import url("assets/css/components/card/");
@import url("assets/css//components/projects/");
@import url("assets/css//components/tab/");
@import url("assets/css//components/sidebar/");

* {
  /* font-family: "Archivo Narrow", sans-serif; */
  font-family: "Montserrat", system-ui !important;
}
.jYxAGf {
  z-index: 9999 !important;
}
/* .modal-backdrop {
  z-index: 2 !important;
} */

body {
  background-color: #ededed;
  overflow-x: hidden;
  background-image: url("/assets/img/bg-coming-soon-dark.png");
  background-size: cover;
  background-repeat: no-repeat;
}
html {
  overflow-x: hidden;
}

.title {
  /* color: #6642de; */
  color: #fb9503;
  font-size: 35px;
  font-weight: bold;
}

.text {
  font-size: 23px;
  font-weight: 500;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-orange {
  /* background-color: #e59880; */
  background-color: #000000;
  border-radius: 30px;
  padding: 0.4rem 1rem;
  color: #ffffff;
  width: auto;
  font-weight: bold;
  /* color: #6642de; */
  color: #fb9503;
}

.btn-purple,
.btn-buy {
  /* background-color: #6642de; */
  border-radius: 30px;
  padding: 0.4rem 1rem;
  color: #ffffff;
  width: auto;
  font-weight: bold;
  color: #ffffff;
  background-color: #fb9503;
  color: #000000;
}

.btn-orange:hover {
  /* background-color: #6642de; */
  color: #ffffff;
  background-color: #fb9503;
}

.btn-purple:hover,
.btn-buy:hover {
  color: #ffffff;
  background-color: #000000;
}

.btn-buy.active {
  border-radius: 30px 30px 0 0;
  color: #ffffff;
  background-color: #000000;
  margin-bottom: 0 !important;
}

#main {
  transition: margin-right 0.5s;
}

#main.active {
  margin-right: 250px;
  padding: 0 10px;
}

.form-sidebar {
  padding: 0 1rem;
}

.form-sidebar .form-control {
  background-color: transparent;
  border: 2px solid #ededed;
  border-radius: 15px;
  color: #ededed;
  margin-bottom: 0.6rem;
}

.form-sidebar .form-control::placeholder {
  color: #ededed;
}

.form-sidebar .btn {
  /* background-color: #e59880; */
  background-color: #000000;
  color: #ededed;
  width: 100%;
  border-radius: 20px;
  font-weight: 400;
  font-size: 20px;
  padding: 0.2rem;
}

.form-sidebar .btn:hover {
  background-color: #ffffff;
  /* color: #6642de; */
  color: #fb9503;
}

.container-check {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 10px;
  color: #ededed;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #ededed;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  /* background-color: #e59880; */
  background-color: #000000;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swiper-pagination .swiper-pagination-bullet-active {
  /* background: #e59880; */
  background: #000000;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.swal2-popup {
  background-color: #ededed;
}

.swal2-title {
  /* color: #6642de; */
  color: #fb9503;
}

.swal2-styled.swal2-confirm {
  /* background-color: #6642de; */
  background-color: #fb9503;
  color: #ffffff;
  width: 120px;
  border-radius: 20px;
  padding: 0.3rem;
  font-size: 20px;
}

.swal2-styled.swal2-cancel {
  /* background-color: #e59880; */
  background-color: #000000;
  width: 120px;
  border-radius: 20px;
  padding: 0.3rem;
  font-size: 20px;
  color: #ffffff;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

.lds-ring {
  display: flex;
  position: relative;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.swal2-input {
  background-color: transparent !important;
  /* border: 2px solid #6642de !important; */
  border: 2px solid #fb9503 !important;
  /* color: #6642de !important; */
  color: #fb9503 !important;
  border-radius: 25px;
}

.btn-orange-admin {
  background-color: #fb9503;
  color: #000000;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 0.3rem 1.5rem;
  border-radius: 30px;
  width: auto;
  font-weight: bold;
}

.title-orange {
  color: #fbb304;
  font-size: 25px;
}

.whiteBody {
  background-color: #ffffff !important;
}

.fs-5font_thb,
.fs-small {
  font-weight: bold;
}
div:where(.swal2-container) .swal2-progress-steps .swal2-progress-step {
  background: #fb9503 !important;
}

div:where(.swal2-container) .swal2-progress-steps .swal2-progress-step-line {
  background: #fb9503 !important;
}

div:where(.swal2-container)
  .swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step {
  background: #fdbf68 !important;
}

div:where(.swal2-container)
  .swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: #fdbf68 !important;
}

@media (min-width: 1400px) {
  #main.active {
    margin-right: 20%;
    padding: 0 10px;
  }

  .form-sidebar {
    padding: 0 1.5rem;
  }
}

@media (max-width: 1199.98px) {
  #main.active {
    margin-right: 0%;
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .form-sidebar {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (max-width: 767.98px) {
  .title {
    font-size: 30px;
  }
  .text {
    font-size: 18px;
  }

  .form-sidebar {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.white .section-coming-soon {
  color: #000000 !important;
  background-image: url("/assets/img/bg-coming-soon-white.png") !important;
}

.white .section-coming-soon-logo .dark {
  display: none !important;
}

.white .section-coming-soon-logo .white {
  display: block !important;
}

.white .btn-language {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.white .btn-language .white {
  display: block !important;
}

.white .btn-language .dark {
  display: none !important;
}

.white .section-coming-soon-form input {
  border: 1px solid #fb9503 !important;
}

.white .section-coming-soon-button-container {
  border: 1px solid #fb9503 !important;
}

.white .section-coming-soon-footer-logo .white {
  display: block !important;
}

.white .section-coming-soon-footer-logo .dark {
  display: none !important;
}
