.proyects h4 {
  /* color: #6642de; */
  color: #fb9503;
  font-size: 17px;
  font-weight: bold;
  margin-left: 1.5rem;
  font-style: italic;
}

.proyects-list {
  padding-right: 1rem;
  height: 410px;
  overflow: auto;
  padding-top: 1rem;
}

/* width */
.proyects-list::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.proyects-list::-webkit-scrollbar-track {
  background-color: #dbdbdb;
  border-radius: 10px;
}

/* Handle */
.proyects-list::-webkit-scrollbar-thumb {
  /* background: #6642de; */
  background: #fb9503;
  border-radius: 10px;
}

/* Handle on hover */
.proyects-list::-webkit-scrollbar-thumb:hover {
  /* background: #6642de; */
  background: #fb9503;
}

.proyect-list-item {
  position: relative;
  cursor: pointer;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  padding: 0.8rem;
  border-radius: 25px;
  -webkit-box-shadow: 0px 1px 30px -22px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 1px 30px -22px rgba(0, 0, 0, 1);
  box-shadow: 0px 1px 30px -22px rgba(0, 0, 0, 1);
  margin-left: 1rem;
}

.proyect-list-item.draft {
  background-color: transparent !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%23333' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 25px;
  height: 115px;
}

.proyect-list-item.draft span {
  color: #898989;
  font-size: 1rem;
  font-weight: 400;
}

.proyect-list-item.draft:hover span {
  color: #898989 !important;
}
.proyect-list-item.draft:hover {
  color: #898989 !important;
}
.proyect-list-item.draft p {
  /* color: #6642de; */
  color: #fb9503;
}

.proyect-list-item.draft img {
  width: 20px;
}

.proyects-availables-percents {
  position: relative;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  padding: 0.8rem 0;
  border-radius: 25px;
  -webkit-box-shadow: 0px 1px 30px -22px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 1px 30px -22px rgba(0, 0, 0, 1);
  box-shadow: 0px 1px 30px -22px rgba(0, 0, 0, 1);
  flex-direction: column;
  justify-content: center;
}

.proyects-availables-percents-item {
  /* color: #6642de; */
  color: #fb9503;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-bottom: 1px solid #898989;
  width: 100%;
  padding: 1rem 0;
}

.proyects-availables-percents-item:last-child {
  border-bottom: none;
}

.proyects-availables-percents-item span {
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.proyects-availables-percents-item p {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}

.proyects-availables-texts {
  display: flex;
  flex-direction: column;
}

.proyects-availables-texts h6 {
  font-size: 30px;
  /* color: #6642de; */
  color: #fb9503;
  margin-bottom: 1rem;
}

.proyects-availables-texts p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.proyects-availables-texts .btn {
  flex: 1;
  font-size: 14px;
  font-weight: 200;
  color: #ffffff;
  width: 100%;
  margin-bottom: 0.5rem;
}

.proyects-availables-texts .btn:nth-child(2) {
  margin: 0 0.8rem;
}

.proyects-availables-texts .legal {
  font-size: 14px;
  color: #606060;
  text-decoration: none;
  font-weight: 600;
}

.proyects-availables-texts .pdf {
  font-size: 16px;
  font-weight: bold;
  /* color: #6642de; */
  color: #fb9503;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.proyects-availables-texts .pdf img {
  width: 35px;
  margin-right: 0.8rem;
}

.proyect-list-item-texts {
  width: 100%;
}

.proyect-list-item .proyect-list-img {
  width: 90px;
  height: 90px;
  min-height: 100%;
  object-fit: cover;
  border-radius: 20px;
  margin-right: 10px;
}

.proyect-list-item h5 {
  font-size: 20px;
  /* color: #6642de; */
  color: #fb9503;
  margin-bottom: 0.3rem;
  font-style: italic;
  font-weight: 700;
}

.proyect-list-item p {
  font-size: 14px;
  font-weight: 600;
}

.proyect-list-item span {
  font-size: 10px;
}

.icon-list {
  width: 25px;
}

.icon-list.white {
  display: none;
}

.proyect-list-item:hover {
  /* background-color: #6642de; */
  background-color: #fb9503;
  color: #ffffff !important;
}

.proyect-list-item.active {
  /* background-color: #6642de; */
  background-color: #fb9503;
  color: #ffffff !important;
}

.proyect-list-item:hover h5 {
  color: #ffffff;
}

.proyect-list-item.active h5 {
  color: #ffffff;
}

.text-regulation {
  font-size: 18px;
  font-weight: 500;
  padding: 0 1.4rem;
}
.img-regulation {
  width: 80px;
  margin-right: 1rem;
}

.text-buy {
  font-size: 18px;
  font-weight: bold;
  padding: 0 1.4rem;
}

.proyects-regulation-img {
  padding: 0 1.4rem;
}

.img-buy {
  width: 35px;
}

.link-term {
  font-size: 10px;
  color: #000000;
  font-weight: bold;
  text-decoration: none;
}

.link-term:nth-child(1) {
  margin-right: 5px;
}
.link-term:nth-child(2) {
  margin-left: 5px;
}

.btns-proyects-view {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#main.active .container-fluid {
  padding: 0 1%;
}

#main.active .btns-proyects-view {
  flex-direction: column;
}

#main.active .btns-proyects-view .btn {
  width: 100%;
  margin-bottom: 0.5rem;
}

.container-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #898989;
  margin-bottom: 1.5rem;
  position: relative;
}

.content-detail {
  display: none;
  transition: height 1s;
  animation: fadeEffect 1s; /* Fading effect takes 1 second */
  width: 90%;
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.flex {
  display: flex !important;
  flex-direction: column;
}

.img-pro {
  border-radius: 0 0 100px 20px;
  width: 100%;
}

.new-tag {
  position: absolute;
  left: -6px;
  top: -15px;
  /* background-color: #e59880; */
  background-color: #000000;
  /* color: #6642de; */
  color: #fb9503;
  border-radius: 100%;
  padding: 0.8rem 0.1rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-weight: 600;
}

#myProgress {
  width: 90%;
  background-color: #ddd;
  position: absolute;
  top: 0;
  border-radius: 10px 10px 0 0;
}

#myBar {
  width: 18%;
  height: 7px;
  border-radius: 10px 10px 0 0;
  background-color: #24bc24;
}

.myProgress-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.myProgress-text span {
  font-size: 10px;
  color: #898989;
}

@media (min-width: 1400px) {
  .btns-proyects-view .btn {
    font-size: 14px;
  }

  .proyects-availables-percents-item span {
    font-size: 14px;
  }

  .img-buy {
    width: 48px;
  }

  .proyect-list-item p {
    font-size: 17px;
  }

  .proyect-list-item h5 {
    font-size: 21px;
  }
  .proyects-list {
    padding-right: 0;
  }
  .proyect-list-item span {
    font-size: 11px;
    font-weight: 600;
  }

  .proyect-list-item {
    margin: 0 1rem 0 1.3rem;
  }
}

@media (max-width: 1199.98px) {
  #myProgress {
    width: 95%;
  }
  .proyects h4 {
    margin-left: 0;
  }
  .proyect-list-item:hover {
    background-color: #ffffff;
    /* color: #6642de !important; */
    color: #fb9503 !important;
  }

  .proyect-list-item:hover h5 {
    /* color: #6642de; */
    color: #fb9503;
  }

  .proyect-list-item.active {
    /* background-color: #6642de; */
    background-color: #fb9503;
    color: #ffffff !important;
  }

  .proyect-list-item.active h5 {
    color: #ffffff;
  }

  .icon-list {
    width: 20px;
    transform: rotate(91deg);
  }
  .proyect-list-item.active .icon-list {
    display: none;
  }
  h4 {
    /* color: #6642de; */
    color: #fb9503;
  }
  .img-buy {
    width: 70px;
  }

  /* .container-fluid {
    padding: 0 1% 0 0;
  } */

  .proyects-availables-texts h6 {
    font-size: 18px;
  }

  .proyects-availables-texts p {
    font-size: 14px;
  }

  .btns-proyects-view {
    flex-direction: column;
  }

  .proyect-list-item {
    width: 95%;
  }

  .proyects-list {
    padding-right: 10px;
    height: 650px;
  }

  .swiper-projects .swiper-slide {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: -6rem;
    left: -30px;
    position: absolute;
    width: 100%;
  }
  .magic {
    width: 60px;
    border: 3px solid #ffffff;
    border-radius: 20px;
  }

  .swiper-slide::after {
    position: relative;
    box-shadow: none;
  }

  .swiper-slide-shared {
    position: relative;
    justify-content: flex-end;
  }
  .proyect-list-item.active {
    width: 70%;
    z-index: 9;
  }
  .proyects-availables {
    margin-top: 32rem;
  }
}

@media (max-width: 991.98px) {
  #myProgress {
    width: 95%;
  }

  .myProgress-text {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .proyects-availables-texts h6 {
    font-size: 18px;
  }

  .proyects-availables-texts p {
    font-size: 14px;
  }

  .proyects-availables {
    margin-top: 24rem;
  }

  .text-regulation,
  .proyects-regulation-img,
  .text-buy {
    padding: 0 3rem !important;
  }
  .proyect-list-item {
    width: 90%;
  }
}

@media (max-width: 767.98px) {
  .img-buy {
    width: 50px;
  }
  .proyects-availables {
    margin-top: 22rem;
  }
  .text-regulation,
  .proyects-regulation-img,
  .text-buy {
    padding: 0 2.5rem !important;
  }
}

@media (max-width: 575.98px) {
  .text-regulation,
  .proyects-regulation-img,
  .text-buy {
    padding: 0 2rem !important;
  }
  .img-buy {
    width: 30px;
  }

  .proyects-availables-texts h6 {
    font-size: 16px;
  }
  .swiper-projects .swiper-slide {
    margin-top: -6rem;
    width: 105%;
  }

  .proyect-list-item.active {
    width: 90%;
    z-index: 9;
  }
  .proyects-availables {
    margin-top: 16rem;
  }
  .proyects-availables-texts {
    margin-top: 1.5rem;
  }
}

@media (max-width: 500.98px) {
  .proyects-availables {
    margin-top: 11rem;
  }
}
