.card {
  border-radius: 5px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.27);
  border: none;
  padding: 1rem;
}

.card-title,
.card-text {
  color: #000000;
}

.breadcrumb-item a {
  color: #fb9503;
}

.card .form-control,
.form-select {
  border-radius: 25px;
  border: 1px solid #fb9503;
}

.card .form-control::placeholder {
  color: #ededed;
}
